import React from "react";
import { Link } from "react-router-dom";

export default function Aboutus() {
  return (
    <>
      <div class="hero-1 oh pos-rel">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <div class="hero-1-content wow fadeInLeft animated">
                {/* <!--<h5 class="cate  wow fadeInUp animated" data-wow-delay="0.2s">#Bank Loan</h5>--> */}
                <h1 class="title  wow fadeInUp animated" data-wow-delay="0.4s">
                  Connect with us
                </h1>
                <p class=" wow fadeInUp animated" data-wow-delay="0.6s"></p>
                <Link
                  to="mailto:contactus@jupiter.credit"
                  class="btn theme-btn-1"
                >
                  Reach Out to Us <i class="las la-angle-right"></i>
                </Link>
              </div>
            </div>
            <div class="col-lg-6 d-lg-block">
              <div
                class="hero-1-thumb-15 wow fadeInUp animated"
                data-wow-delay="0.4s"
              >
                <img
                  class="img-fluid wow fadeInRight animated"
                  src="images/hand.png"
                  alt="hero-1"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="reachout mx-50 py-50">
        <div
          class="container"
          style={{ background: "#FBFBFB", borderRadius: "40px" }}
        >
          <div class="row">
            <div class="col-md-4 ">
              <h2 class="p-5">Reach Out to Us</h2>
            </div>
            <div class="col-md-8 p-5">
              <div class="inner-wraper">
                <h4 style={{ color: "#FC7A69", marginLeft: "-30px" }}>
                  Customer care
                </h4>
                <ul>
                  <li>Phone : +919136927499</li>
                  <li>Email: contactus@jupiter.credit</li>
                  <li>
                    Address: 3rd floor, Viraj Buildings, SV Road, Khar West,
                  </li>
                  <li>Mumbai 400052, Maharashtra, India</li>
                </ul>
              </div>
              {/* <div class="inner-wraper mt-4">
                <h4 style={{ color: "#FC7A69", marginLeft: "-30px" }}>
                  Rajesh R Singh
                </h4>
                <ul>
                  <li>Amica Finance Private Limited</li>
                  <li>
                    Address: 3rd floor, Viraj Buildings, SV Road, Khar West,
                    Mumbai 400052, Maharashtra, India
                  </li>
                  <li>Email: grievance@jupiter.credit</li>
                </ul>
              </div> */}
              {/* <div class="inner-wraper mt-4">
                <h4 style={{ color: "#FC7A69", marginLeft: "-30px" }}>
                  Piyush Kabra
                </h4>
                <h4 style={{ marginLeft: "-30px" }}>Nodal Officer</h4>
                <ul>
                  <li>Amica Finance Private Limited</li>
                  <li>
                    Address: 3rd floor, Viraj Buildings, SV Road, Khar West,
                    Mumbai 400052, Maharashtra, India{" "}
                  </li>
                  <li>Email: compliance@jupiter.credit </li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div class="ask-question-section p-50">
        <div class="container">
          <h2 class="text-center">Frequently Asked Questions</h2>

          <div class="accordion mt-30 mb-30">
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                >
                  How is my credit limit calculated?
                </button>
              </h2>
              <div
                id="collapseOne"
                class="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p>
                    Your credit limit is calculated using a combination of your
                    Credit Score and account history on Jupiter.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingTwo">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  What can I do if the auto debit for the due amount fails?
                </button>
              </h2>
              <div
                id="collapseTwo"
                class="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p>
                    {" "}
                    In case your auto debit fails, you can always pay the due
                    amount manually.
                  </p>
                </div>
              </div>
            </div>
            <div class="accordion-item">
              <h2 class="accordion-header" id="headingThree">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  How do I apply for a loan?
                </button>
              </h2>
              <div
                id="collapseThree"
                class="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div class="accordion-body">
                  <p>
                    {" "}
                    First download The Jupiter App. Then you can apply for a
                    loan by entering some basic details like your monthly
                    income. After verifying your details, we will notify you
                    immediately if you're eligible to withdraw money up to a
                    certain amount"
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

import Mainheader from "./components/Mainheader";
import Mainfooter from "./components/Mainfooter";
import Home from "./components/Home";
import Aboutus from "./components/Aboutus";
import Regulatory from "./components/Regulatory";
import { BrowserRouter, Route, Routes } from "react-router-dom";

function App() {
  return (
    <>
      <BrowserRouter>
        <Mainheader />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/aboutus" element={<Aboutus />} />
          <Route path="/regulatory" element={<Regulatory />} />
        </Routes>
        <Mainfooter />
      </BrowserRouter>
    </>
  );
}

export default App;

import React from "react";
import { Link } from "react-router-dom";

export default function Mainfooter() {
  return (
    <>
      {/* <!-- Footer --> */}
      <footer className="footer-style bg-gray-100 pt-0">
        <div className="container">
          <div className="row"></div>
          <div className="footer-middle-area mt-0  pt-60">
            <div className="row">
              <div className="col-xl-8 col-lg-6 col-md-6">
                <div className="footer-wrapper mb-30">
                  <Link to="/">
                    <h3
                      className="footer-title"
                      style={{
                        fontSize: "34px",
                        color: "#fc7a69",
                        fontWeight: "800",
                      }}
                    >
                      AFPL
                    </h3>
                  </Link>
                  <div className="footer-bottom-area ">
                    <div className="row">
                      <div className="col-xl-12 col-lg-6 col-md-6">
                        <div className="footer-bottom-link text-start">
                          <ul>
                            <li>
                              <Link to="/aboutus">About Us </Link>
                            </li>
                            {/* <li>
                              <Link to="#"> Product Offered</Link>
                            </li> */}
                            <li>
                              <Link to="mailto:contactus@jupiter.credit">
                                Reach out to us
                              </Link>
                            </li>
                            <li>
                              <Link to="/regulatory">Regulatory </Link>
                            </li>
                            {/* <li>
                              <Link to="#">Help </Link>
                            </li>
                            <li>
                              <Link to="#">Privacy Policy </Link>
                            </li> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="footer-bottom-area ">
                  <div className="row">
                    <div className="col-xl-12 col-lg-6 col-md-6">
                      <div className="copyright">
                        <p>
                          © Copyrights 2023
                          <Link to="#"> Amica Finance Pvt.Ltd </Link> All
                          rights reserved.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-4 col-lg-3 col-md-6">
                <div className="footer-wrapper mb-30">
                  <h3 className="footer-title" id="app_title">
                    Get The App
                  </h3>

                  <div className="store">
                    <div className="row d-block" id="app-st">
                      <div className="col" id="fst-st">
                        <Link
                          to="https://jptr.onelink.me/TOMp/81c06bec"
                          target="_blank"
                        >
                          <img
                            src="images/services/icon2.png"
                            alt=""
                            width={"38%"}
                          />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="footer-end-part">
        <div className="1st-img">
          <img
            src="images/footerbg.png"
            style={{ width: "100%" }}
            alt="footer-image-one"
          />
        </div>
      </div>
      {/* <!-- /Footer --> */}

      {/* <!-- Go top --> */}
      <div className="go-top-area">
        <div className="go-top-wrap">
          <div className="go-top-btn-wrap">
            <div className="go-top go-top-btn">
              <i className="las la-angle-double-up"></i>
              <i className="las la-angle-double-up"></i>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /Go top --> */}
    </>
  );
}

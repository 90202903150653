import React from "react";
import { Link, NavLink } from "react-router-dom";

const openNav = () => {
  document.getElementById("mySidenav").style.width = "100%";
  document.getElementById("main").style.marginLeft = "0px";
};

const closeNav = () => {
  document.getElementById("mySidenav").style.width = "0";
  document.getElementById("main").style.marginLeft = "0";
};

export default function header() {
  return (
    <>
      {/* <!-- Header --> */}
      <div className="navbar-area" id="tgl">
        <div className="acavo-responsive-nav">
          <div className="container-fluid">
            <div className="acavo-responsive-menu">
              <div className="logo">
                <Link to="/">
                  {/* <!--<img src="images/logo.png" alt="logo">--> */}
                  <span>AFPL</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="acavo-nav">
          <div className="container-fluid">
            <nav className="navbar navbar-expand-md navbar-light">
              <Link className="navbar-brand" to="/">
                <span>AFPL</span>
                {/* <!--<img src="images/logo.png" alt="logo">--> */}
              </Link>
              <div className="collapse navbar-collapse mean-menu">
                <ul className="navbar-nav na" id="tglm">
                  <li className="nav-item">
                    <NavLink to="/" className="nav-link">
                      Home
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="aboutus" className="nav-link">
                      About Us
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink to="regulatory" className="nav-link">
                      Regulatory
                    </NavLink>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
        <div className="others-option-for-responsive">
          {/* <!-- Container --> */}
          <div className="container">
            <div className="dot-menu">
              <Link className="tgl-btn" onClick={"tgl_fun"}>
                <div
                  className="inner d-none"
                  id="tgli"
                  style={{ Display: "none" }}
                >
                  <i className="uil uil-bars"></i>
                </div>
              </Link>
            </div>
          </div>
          {/* <!-- /Container --> */}
        </div>
      </div>

      {/* <!-- Header --> */}
      <div className="navbar-area" id="mob-header">
        <nav className="navbar navbar-expand-md navbar-light">
          <Link className="navbar-brand" to="/">
            {/* <!--<img src="images/logo.png" alt="logo">--> */}
            <span>AFPL</span>
          </Link>
          <div id="main">
            <span
              style={{ fontSize: "30px", cursor: "pointer" }}
              onClick={openNav}
            >
              &#9776;
            </span>
          </div>
          <div id="mySidenav" className="sidenav">
            <Link className="closebtn" onClick={closeNav}>
              &times;
            </Link>
            <Link className="active" to="/">
              Home
            </Link>
            <Link to="/aboutus">About Us</Link>
            <Link to="/regulatory">Regulatory</Link>
          </div>
        </nav>
      </div>
      {/* <!-- /Header --> */}
    </>
  );
}

import React from "react";
import { Link } from "react-router-dom";

export default function Home() {
  return (
    <>
      <div className="hero-1 oh pos-rel">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="hero-1-content wow fadeInLeft animated">
                <h1
                  className="title  wow fadeInUp animated"
                  data-wow-delay="0.4s"
                >
                  Empowering you. Every step of the way.
                </h1>
                <p className=" wow fadeInUp animated" data-wow-delay="0.6s">
                  AFPL is a digital lending entity that offers personal loans
                  through a simple, fast online application process, with the
                  help of our lending partners.
                </p>
              </div>
            </div>
            <div className="col-lg-6 d-lg-block">
              <div
                className="hero-1-thumb-15 wow fadeInUp animated"
                data-wow-delay="0.4s"
              >
                <img
                  className="img-fluid wow fadeInRight animated"
                  src="images/hero/dd.webp"
                  alt="hero-1"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!--everyThing Money Start--> */}
      <div className="everything-monry-area text-center pt-100 pb-0 bg-light">
        <h2 className="title">Get Instant Loan with your Jupiter app!</h2>
        <p className="pb-20 pt-20">
          Instant loans with zero paperwork, all on one app
        </p>
        <div className="footer-link">
          <Link
            to="https://jptr.onelink.me/TOMp/81c06bec"
            target="_blank"
            id="btn1"
          >
            <img src="images/hero/icon1.png" alt="" width={"26%"} />
          </Link>

          <Link
            to="https://jptr.onelink.me/TOMp/81c06bec"
            target="_blank"
            className="d-none"
            id="btn2"
          >
            <img src="images/services/icon2.png" alt="" />
          </Link>

          {/* <Link to="#" className="btn">
    <img src="images/hero/google-pay.png" alt="" />
    </Link> */}
        </div>

        <img src="images/services/mb.png" alt="bank" />
      </div>

      {/* <!--your account is back--> */}
      <div className="account-back pt-80 pb-80">
        <div className="section-title text-center">
          <h2 className="title text-white">
            Get a smart account too! Which is backed by
          </h2>
        </div>
        <div className="row pt-80">
          <div className="col-md-4 col-12 col">
            <div className="inner p-20">
              <div className="row">
                <div className="col-md-4  text-end" id="acc-img">
                  <img src="images/services/hht.png" alt="img" />
                </div>
                <div className="col-md-8">
                  <div className="title">
                    <h3 className="text-white">Trust</h3>
                  </div>
                  <p className="text-white">
                    We host your account in an RBI-
                    <br />
                    licensed bank{" "}
                  </p>
                  <img src="images/services/federal_bank.png" alt="img" />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-12 col">
            <div className="inner p-20">
              <div className="row">
                <div className="col-md-4  text-end" id="acc-img">
                  <img src="images/services/LOCK.png" alt="img" />
                </div>
                <div className="col-md-8">
                  <div className="title">
                    <h3 className="text-white">Security</h3>
                  </div>
                  <p className="text-white">
                    Which means bank-grade security
                    <br /> and ISO and PCI compliant{" "}
                  </p>
                  <img src="images/services/Vector.png" alt="img" />
                  <img src="images/services/gms.png" alt="img" />
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-12 col">
            <div className="inner p-20">
              <div className="row">
                <div className="col-md-4  text-end" id="acc-img">
                  <img src="images/services/SAF.png" alt="img" />
                </div>
                <div className="col-md-8">
                  <div className="title">
                    <h3 className="text-white">Safety</h3>
                  </div>
                  <p className="text-white">
                    Your money is insured up to <br /> ₹5,00,000
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!--everyThing Money End--> */}
      <div className="services-area pt-80 pb-0 center">
        {/* <!-- Container --> */}
        <div className="container">
          {/* <!-- row --> */}
          <div className="row justify-content-center text-center">
            {/* <!-- col --> */}
            <div className="col-lg-8 col-md-12 mb-50">
              <div className="section-title">
                <h2 className="title">Why Choose AFPL?</h2>
              </div>
            </div>
            {/* <!-- /col --> */}
          </div>
          {/* <!-- /row --> */}
          {/* <!-- row --> */}
          <div
            style={{
              background: 'url("images/services/Circle.png")',
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              maxWidth: "860px !important",
              margin: "auto",
              overflow: "visible",
              padding: "23px",
            }}
          >
            <div className="row">
              {/* <!-- col --> */}
              <div className="col-lg-4 col-md-6">
                <div className="single-services">
                  <h3>
                    Safe & <br /> Transparent
                  </h3>
                  <div className="image mt-3">
                    <img
                        src="images/services/Group-402513752.png"
                        alt="image"
                      />
                  </div>
                </div>
              </div>
              {/* <!-- /col -->
                <!-- col --> */}
              <div className="col-lg-4 col-md-6">
                <div className="single-services">
                  <h3>
                   Fast Disbursals
                  </h3>
                  <div className="image mt-3">
                    <img src="images/services/2s.png" alt="image" />
                  </div>
                </div>
              </div>
              {/* <!-- /col -->
                <!-- col --> */}
              <div className="col-lg-4 col-md-6">
                <div className="single-services">
                  <h3>
                    Paperless Process
                  </h3>
                  <div className="image mt-3">
                      <img src="images/services/3s.png" alt="image" />
                  </div>
                </div>
              </div>
              {/* <!-- /col -->
                <!-- col --> */}
              <div className="col-lg-4 col-md-6">
                <div className="single-services">
                  <h3>
                      Flexible <br />
                      Repayment <br />
                      Options
                  </h3>
                  <div className="image mt-3">
                      <img src="images/services/4s.png" alt="image" />
                  </div>
                </div>
              </div>
              {/* <!-- /col -->
                <!-- col --> */}
              <div className="col-lg-4 col-md-6">
                <div className="single-services">
                  <h3>
                    Competitive Interest Rates
                  </h3>
                  <div className="image mt-3">
                      <img src="images/services/4s.png" alt="image" />
                  </div>
                </div>
              </div>
              {/* <!-- /col -->
                <!-- col --> */}
              <div className="col-lg-4 col-md-6">
                <div className="single-services">
                  <h3>
                    No hidden Charges
                  </h3>
                  <div className="image mt-3">
                      <img src="images/services/6s.png" alt="image" />
                  </div>
                </div>
              </div>
              {/* <!-- /col --> */}
            </div>
          </div>
          {/* <!-- /row --> */}
        </div>
        {/* <!-- /Container --> */}
      </div>

      {/* <!-- product offer --> */}
      <div className="services-area pt-0 pb-50" id="pd-off">
        <div className="container-fluid">
          <div className="row pt-50">
            {/* <!-- col --> */}
            <div className="col-12 col-md-12">
              <div className="single-services2">
                <h2 className="title">Products we offer</h2>
                <div className="row pt-100" id="sd">
                  <div className="col-md-5 col-6" id="fl-wdth">
                    <img
                      src="images/services/on-demand.png"
                      alt="image"
                      width="80%"
                    />
                  </div>
                  <div
                    className="col-md-7 col-6 d-block align-items-center mt-40"
                    id="fl-wdth"
                  >
                    <div className="d-block">
                      <h3>Mini Loans</h3>
                      <p style={{ fontSize: "20px" }}>
                        Get a 0% interest loan
                        <br /> with a 45-day repayment period.
                      </p>
                    </div>
                    <div className="d-block">
                      <h3>Personal Loans</h3>
                      <p style={{ fontSize: "20px" }}>
                        Pay back in EMIs
                        <br />
                        Zero Foreclosure Charges
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- product offer --> */}

      {/* <!-- /blog --> */}
      {/* <div className="services-area pt-50 ">
        <div className="container">
          <div className="row ">
            <div className="col-lg-12 col-md-12 mb-0">
              <div className="section-title text-center">
                <h2 className="title ">News</h2>
              </div>
            </div>
            <div className="blog-area pt-50 pb-50 ">
              <div className="container p-0">
                <div className="row"> */}
                  {/* <!-- col --> */}
                  {/* <div className="col-lg-4 col-md-6">
                    <div className="single-services3">
                      <div className="image2 mt-3">
                        <Link
                          target="_blank"
                          to="https://economictimes.indiatimes.com/tech/startups/neobank-jupiter-secures-nbfc-licence-will-raise-debt-to-push-lending-business/articleshow/99769248.cms"
                        >
                          <img src="images/Group.png" alt="image" />
                        </Link>
                      </div>
                      <h3 className="mt-20">
                        <Link
                          target="_blank"
                          to="https://economictimes.indiatimes.com/tech/startups/neobank-jupiter-secures-nbfc-licence-will-raise-debt-to-push-lending-business/articleshow/99769248.cms"
                        >
                          Neobank Jupiter secures NBFC licence
                        </Link>
                      </h3>
                      <div className="d-block mt-20">
                        <Link
                          target="_blank"
                          to="https://economictimes.indiatimes.com/tech/startups/neobank-jupiter-secures-nbfc-licence-will-raise-debt-to-push-lending-business/articleshow/99769248.cms"
                          className="theme-btn-1"
                        >
                          Explore<i className="las la-long-arrow-alt-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div> */}
                  {/* <!-- /col -->
                <!-- col --> */}
                  {/* <div className="col-lg-4 col-md-6">
                    <div className="single-services3">
                      <div className="image2 mt-3">
                        <Link
                          target="_blank"
                          to="https://currentaffairs.adda247.com/neobank-jupiter-secures-nbfc-licence/"
                        >
                          <img src="images/Group.png" alt="image" />
                        </Link>
                      </div>
                      <h3 className="mt-20">
                        <Link
                          target="_blank"
                          to="https://currentaffairs.adda247.com/neobank-jupiter-secures-nbfc-licence/"
                        >
                          Jupiter gets RBI nod for NBFC
                        </Link>
                      </h3>
                      <div className="d-block mt-20">
                        <Link
                          target="_blank"
                          to="https://currentaffairs.adda247.com/neobank-jupiter-secures-nbfc-licence/"
                          className="theme-btn-1"
                        >
                          Explore<i className="las la-long-arrow-alt-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div> */}
                  {/* <!-- /col -->
                <!-- col --> */}
                  {/* <div className="col-lg-4 col-md-6">
                    <div className="single-services3">
                      <div className="image2 mt-3">
                        <Link
                          target="_blank"
                          to="https://inc42.com/buzz/neobanking-soonicorn-jupiter-nbfc-licence-from-rbi/"
                        >
                          <img src="images/Group.png" alt="image" />
                        </Link>
                      </div>
                      <h3 className="mt-20">
                        <Link
                          target="_blank"
                          to="https://inc42.com/buzz/neobanking-soonicorn-jupiter-nbfc-licence-from-rbi/"
                        >
                          Neobanking Soonicorn Jupiter Gets NBFC
                        </Link>
                      </h3>
                      <div className="d-block mt-20">
                        <Link
                          target="_blank"
                          to="https://inc42.com/buzz/neobanking-soonicorn-jupiter-nbfc-licence-from-rbi/"
                          className="theme-btn-1"
                        >
                          Explore<i className="las la-long-arrow-alt-right"></i>
                        </Link>
                      </div>
                    </div>
                  </div> */}
                {/* </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <!-- /blogs -->
    
    <!-- Counter --> */}
      {/* <div className="services-area2 pt-50 pb-50 d-none" style={{background: 'url("images/services/BG.png")', backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}>
        <div className="container">
            <div className="row justify-content-center text-center">
                <div className="col-lg-8 col-md-12 mb-0">
                    <div className="section-title">
                        <h2 className="title">Our journey so far </h2>
                        <p>Here's a peek at our milestones</p>
                    </div>
                </div>
            </div>
            
            <div className="container mt-15" id="ctn">
            <div className="row">
                <div className=" col-md-3 col-6 col  text-center border-right">
                <h2 className="timer count-title count-number" data-to="5" data-speed="1500"></h2>
                <p>Service Geographics</p>
                 </div>
                 <div className="col-md-3 col-6 col text-center border-right">
                <h2 className="timer count-title count-number" data-to="30" data-speed="1500"></h2>
                <p>Fortune 500 Clients</p>
                 </div>
                 <div className="col-md-3 col-6 col text-center border-right">
                <h2 className="timer count-title count-number" data-to="300" data-speed="1500"></h2>
                <p>Dedicated Members</p>
                 </div>
                 <div className="col-md-3 col-6 col text-center">
                <h2 className="timer count-title count-number" data-to="8" data-speed="1500"></h2>
                <p>Years of Journey</p>
                 </div>
            </div>
            </div>
        </div>
        
    </div> */}

      {/* Counter end  */}
      <div
        className="cta-area pt-50"
        style={{
          background: 'url("images/services/BG.png")',
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div
          className="cta-area pt-100 pb-100"
          style={{ backgroundColor: "#003F40" }}
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-12">
                <div className="get-start-box">
                  <div className="col-lg-5">
                    <img src="images/services/ft.png" alt="" />
                  </div>
                  <div className="col-lg-7">
                    <div className="section-heading">
                      <h2>Got a Question?</h2>
                      <p>
                        Your employees can get an Instant loan at 0%,
                        <br /> interest & 0% processing fee*.
                      </p>
                      <Link
                        to="mailto:contactus@jupiter.credit"
                        className="btn theme-btn-1"
                      >
                        Reach Out to Us <i className="las la-angle-right"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
